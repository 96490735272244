/* 
Inflexer Face: frontend of Inflexer

__author__ = 'Taeyang Yang (tyami@naver.com)'
__copyright__ = 'Copyright (C) 2024-, Taeyang Yang. All rights reserved.'
*/

import { DEBUG, NEW_NOTICE, NEW_OPINION } from './configs.js';
import icon_opinion from "./common/icon-opinion.png"; // "./icon-opinion-new.png"
import icon_opinion_new from "./common/icon-opinion-new.png"; // "./icon-opinion-new.png"
import icon_notice from "./common/icon-notice.png"; // icon-notice-new.png
import icon_notice_new from "./common/icon-notice-new.png"; // icon-notice-new.png
import logo from "./common/logo-text.webp";

export const BASE_WEB_URL = DEBUG ? 'http://localhost:3000' : 'https://inflexer.net';
export const GUIDE_URL = 'https://blog.naver.com/tyami/223500172766';
export const OPEN_URL = "https://blog.naver.com/tyami/223488321051";
export const NOTICE_URL = 'https://bit.ly/m/inflexer';
export const OPINION_URL = "https://forms.gle/y3Jr9D2D6Rdh6mwe8";
const PRESS_WEB_URL = BASE_WEB_URL + '/press';
const SHIPPING_WEB_URL = BASE_WEB_URL + '/shipping';
const MAP_WEB_URL = BASE_WEB_URL + '/map';

const BASE_API_URL = DEBUG ? 'http://localhost:5000' : 'https://inflexer.net:5000';
export const API_ENDPOINT_SEARCH = BASE_API_URL + '/search';
export const API_ENDPOINT_COUNT = BASE_API_URL + '/count';
export const API_ENDPOINT_RELOAD = BASE_API_URL + '/reload';
export const API_ENDPOINT_PRESS = BASE_API_URL + '/press';
export const API_ENDPOINT_SHIPPING = BASE_API_URL + '/shipping';
export const API_ENDPOINT_MAP = BASE_API_URL + '/map';

export const NOTICE_MESSAGE = `<a href="${BASE_WEB_URL}" target="_blank">테이블</a> | <a href="${MAP_WEB_URL}" target="_blank">지도</a> | <a href="${SHIPPING_WEB_URL}" target="_blank">배송형</a> | <a href="${PRESS_WEB_URL}" target="_blank">기자단</a> | <a href="${OPEN_URL}" target="_blank">오류 신고</a>`;

export const NOTICE_ICON = NEW_NOTICE ? icon_notice_new : icon_notice;
export const OPINION_ICON = NEW_OPINION ? icon_opinion_new : icon_opinion;
export const LOGO_IMG = logo;

export const NAVER_MAPS_CLIENT_ID = DEBUG? '2t9ai21bun' : 'txy63610k0';
