/*
Inflexer Face: frontend of Inflexer

__author__ = 'Taeyang Yang (tyami@naver.com)'
__copyright__ = 'Copyright (C) 2024-, Taeyang Yang. All rights reserved.'
*/
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { SHOW_NOTICE_IN_BODY } from '../configs.js';
import { BASE_WEB_URL, LOGO_IMG, NOTICE_ICON, NOTICE_URL, GUIDE_URL, OPINION_ICON, OPINION_URL, API_ENDPOINT_SEARCH, API_ENDPOINT_COUNT, NOTICE_MESSAGE } from '../constants.js';
import Filters from './Filter.js';
import ResultsTable from '../common/ResultsTable.js';
import GoogleAdSense from '../common/GoogleAdsense.js';
import { formatDate, scrollToTop } from '../common/utils';
import '../App.css'; 

const AppMain = ({ showNotice, showBannerAd, showFooterAd }) => { // showAd를 props로 받음
  const [query, setQuery] = useState('');
  const inputRef = useRef(null);
  const [mediaFilters, setMediaFilters] = useState(['BP_']);
  const [typeFilters, setTypeFilters] = useState(['VST', 'SHP']);
  const [pointFilters, setPointFilters] = useState(['O', 'X']);
  const [targetFilter, setTargetFilter] = useState('TOTAL');
  const [results, setResults] = useState([]);
  const [countMessage, setCountMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [NoResultsMessage, setNoResultsMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const search = async () => {
    if (!query.trim()) {
      return;
    }
    setLoading(true);
    setNoResultsMessage('검색 중 ... <br/><br/>체험단 사이트에 대신 다녀올게요. 🏃🏃🏃‍♀️<br/>조금만 기다려주세요!');
    const params = new URLSearchParams();
    params.append('query', query);
    if (mediaFilters.length > 0) {
      mediaFilters.forEach(media => params.append('media', media));
    }
    if (typeFilters.length > 0) {
      typeFilters.forEach(type => params.append('type', type));
    }
    if (pointFilters.length > 0) {
      pointFilters.forEach(point => params.append('point', point));
    }    
    params.append('target', targetFilter);

    try {
      const response = await fetch(`${API_ENDPOINT_SEARCH}?${params.toString()}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      
      setResults(data.result);
      if (data.is_valid) {
        if (data.result.length === 0) {
          setCountMessage(`적합한 체험이 없네요. 다른 검색어를 입력해보세요!<br /><small>당신을 위한 <a href="${GUIDE_URL}" target="_blank"><strong>가이드</strong></a>를 준비했어요.</small>`);
          setNoResultsMessage('');
        } else {
          setCountMessage(`당신을 위한 ${data.num_result}개의 체험이 있어요!<br /><small>플랫폼/업체/제공/신청/리뷰를 클릭해보세요.<br/>정렬해서 볼 수 있어요.</small>`);
        }
      } else {
        setCountMessage(`결과가 너무 많아서 사이트에 띄울 수 없어요! (${data.num_result}건)<br /><small><a href="${GUIDE_URL}" target="_blank"><strong>가이드</strong></a>를 참고해서 더 자세한 키워드를 써보세요.</small>`);
        setNoResultsMessage('');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      setCountMessage('지금은 사용자가 많아요. 잠시 후 다시 시도해주세요!<br /><small>오랜 시간 문제가 지속될 경우 "공지" 댓글로 알려주세요.</small>');
    } finally {
      setLoading(false);
    }
  };

  const getCount = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT_COUNT}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCountMessage(`현재 ${data.num_campaign}개의 체험이 있어요!<br/><small><strong>${data.num_platform}개 플랫폼을 한꺼번에 찾아 드릴게요. </strong><br /><a href="https://blog.naver.com/tyami/223500172766" target="_blank">검색 가이드</a>를 참고해서 지역과 키워드를 입력해주세요.<br />(예: 서울 카페, 제주 펜션, 충남 캠핑, 의자, 배터리...)<br /></small>`);
    } catch (error) {
      console.error('Failed to fetch count:', error);
      setCountMessage('서버 보수 중이예요! 잠시 후 다시 시도해주세요.');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (filterType, value) => {
    let setFilter;
    let filters;
  
    switch (filterType) {
      case 'media':
        setFilter = setMediaFilters;
        filters = mediaFilters;
        break;
      case 'type':
        setFilter = setTypeFilters;
        filters = typeFilters;
        break;
      case 'point':
        setFilter = setPointFilters;
        filters = pointFilters;
        break;
      default:
        return; // Unknown filter type, do nothing
    }

    setFilter(
      filters.includes(value)
        ? filters.filter(item => item !== value)
        : [...filters, value]
    );
  };

  const handleTargetRadioChange = (value) => {
    setTargetFilter(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  const columns = useMemo(
    () => [
      { Header: '플랫폼', accessor: 'domain' },
      { 
        Header: '업체', 
        accessor: 'title',
        Cell: ({ row }) => <a href={row.original.url} target="_blank" rel="noopener noreferrer">{row.original.title}</a>,
      },
      { Header: '제공', accessor: 'offer' },
      {
        Header: '신청', 
        accessor: 'apl_due_dt',
        Cell: ({ value }) => formatDate(value),
      },
      { 
        Header: '리뷰', 
        accessor: 'pub_due_dt',
        Cell: ({ value }) => formatDate(value),
      },
    ],
    []
  );
  
  useEffect(() => {
    getCount(); // 컴포넌트가 처음 렌더링될 때 데이터 개수를 가져옵니다.
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (query.trim()) {
      search(); // query가 존재할 때만 search를 실행합니다.
    }
  }, [targetFilter]);

  return (
    <div className={showNotice ? "container-notice" : "container"}>
      {showNotice && (
          <div className="notice">
            <div className="notice-content" dangerouslySetInnerHTML={{ __html: NOTICE_MESSAGE}}>
            </div>
          </div>
      )}
      <section className="header">
        <div className="title" id="title" aria-label="인플렉서(INFLEXER)">
          <a href={BASE_WEB_URL} title="인플렉서 홈페이지로 이동">
            <img src={LOGO_IMG} alt="인플렉서(INFLEXER) 로고" className="logo"/>
            <span class="sr-only">인플렉서(INFLEXER)</span>
          </a>
        </div>
        <div className="header_link_box">
          <a id="opinion" href={OPINION_URL} target="_blank" title="의견 제출 폼">
            <img src={OPINION_ICON} alt="의견" className="icon_opinion"/>
          </a>
          <a id="maker" href={NOTICE_URL} target="_blank" title="공지사항">
            <img src={NOTICE_ICON} alt="공지" className="icon_notice"/>
          </a>
        </div>
      </section>

      <section className="main">
        <div className="slogan" id="slogan">
          체험단 신청을 쉽게, 빠르게, 편리하게!
        </div>

        <div className="filter_button">
          <Filters 
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            mediaFilters={mediaFilters}
            handleCheckboxChange={handleCheckboxChange}
            typeFilters={typeFilters}
            pointFilters={pointFilters}
          />
        </div>

        <div className="input_container">
          <input 
            ref={inputRef}
            type="text" 
            value={query} 
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown} 
            placeholder='검색어를 입력하세요 (예: 서울 강남, 제주 펜션)'
          />
          <button 
            onClick={search} 
            disabled={loading} 
            id="search"
          >
            검색
          </button>
        </div>
              
        <div className='count_message' dangerouslySetInnerHTML={{ __html: countMessage }}></div>
        
        {showBannerAd && (
          <div className="ads_google_banner" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', width: '100%', padding: '20px' }}>
            <GoogleAdSense
                client="ca-pub-7971368984672974" 
                slot="8845447829" 
                style={{ display: 'block' }} 
                format="auto" 
                responsive="true" 
              />
          </div>
        )}

        {SHOW_NOTICE_IN_BODY && (
          <div className='notice_message_content' style={{ backgroundColor: "white", padding: '0px 20px 20px', border: '2px solid #DBCAEA' }}>
            <p style={{ fontSize: 18, fontWeight: 'bold' }}> 👨‍🔧 서버 개선 작업을 진행하고 있어요 (~12/13).</p>
            <li> PC/와이파이 환경에서는 접속이 안될 수 있어요 (모바일도 간헐적으로...)</li>
            <li> 최대한 빠르게 수정하겠습니다!</li>
          </div>
        )}


        <div className='filter_target'>
          <label><input type="radio" name="target" value="TOTAL" checked={targetFilter === 'TOTAL'} onChange={() => handleTargetRadioChange('TOTAL')} />전체</label>
          <label><input type="radio" name="target" value="TODAY" checked={targetFilter === 'TODAY'} onChange={() => handleTargetRadioChange('TODAY')} />오늘오픈</label>
        </div>

        <div className='result'>
        {loading ? (
          <div className='no_result_message' dangerouslySetInnerHTML={{ __html: NoResultsMessage }}></div>
        ) : (
          <ResultsTable columns={columns} data={results} />
        )}
        </div>

        <div className='goto_top_button'>
            <button className="go-to-top" onClick={scrollToTop}>
                상단으로
            </button>
        </div>

      </section>

      {showFooterAd && (
          <section className="footer">
          </section>
      )}
    </div>
  );
};

export default AppMain;
