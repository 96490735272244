/*
Inflexer Face: frontend of Inflexer

__author__ = 'Taeyang Yang (tyami@naver.com)'
__copyright__ = 'Copyright (C) 2024-, Taeyang Yang. All rights reserved.'
*/
import React, { useState, useEffect } from 'react';
import { NaverMap } from 'react-naver-maps';
import { Container as MapDiv, NavermapsProvider } from 'react-naver-maps';
import { NAVER_MAPS_CLIENT_ID } from '../constants.js';
import MarkerCluster from './MarkerCluster.js';

const ResultsMap = ({ markers, removeInputFocus }) => {
    const [mapCenter, setMapCenter] = useState({ lat: 37.5665, lng: 126.9780 });

    useEffect(() => {
        if (markers.length > 0) {
            const avgLat =
                markers.reduce((sum, marker) => sum + parseFloat(marker.latitude), 0) /
                markers.length;
            const avgLng =
                markers.reduce((sum, marker) => sum + parseFloat(marker.longitude), 0) /
                markers.length;
            setMapCenter({ lat: avgLat, lng: avgLng });
        }
    }, [markers]);

    return (
        <NavermapsProvider ncpClientId={NAVER_MAPS_CLIENT_ID}>
            <MapDiv
                style={{
                    height: window.innerWidth < 768 ? 480 : 520,
                }}
            >
                <NaverMap defaultZoom={9} center={mapCenter}>
                    <MarkerCluster markers={markers} removeInputFocus={removeInputFocus} />
                </NaverMap>
            </MapDiv>
        </NavermapsProvider>
    );
};

export default ResultsMap;
