/*
Inflexer Face: frontend of Inflexer

__author__ = 'Taeyang Yang (tyami@naver.com)'
__copyright__ = 'Copyright (C) 2024-, Taeyang Yang. All rights reserved.'
*/
import React, { useState, useMemo, useEffect } from 'react';
import { BASE_WEB_URL, LOGO_IMG, NOTICE_ICON, NOTICE_URL, OPINION_ICON, OPINION_URL, NOTICE_MESSAGE, API_ENDPOINT_SHIPPING } from '../constants.js';
import Filters from './Filter.js';
import ResultsTable from '../common/ResultsTable.js';
import GoogleAdSense from '../common/GoogleAdsense.js';
import { formatDate, scrollToTop } from '../common/utils.js';
import '../App.css'; 

const AppShipping = ({ showNotice, showBannerAd, showFooterAd }) => {
  const [categoryFilter, setCategoryFilter] = useState('FD_');
  const [categoryName, setCategoryName] = useState('음식');
  const [mediaFilters, setMediaFilters] = useState(['BP_']);
  const [typeFilters, setTypeFilters] = useState(['SHP']);
  const [pointFilters, setPointFilters] = useState(['O', 'X']);
  const [targetFilter, setTargetFilter] = useState('TOTAL');
  const [results, setResults] = useState([]);
  const [countMessage, setCountMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [NoResultsMessage, setNoResultsMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const search = async () => {
    setLoading(true);
    setNoResultsMessage('검색 중 ... <br/><br/>체험단 사이트에 대신 다녀올게요. 🏃🏃🏃‍♀️<br/>조금만 기다려주세요!');
    const params = new URLSearchParams();
    params.append('category', categoryFilter);
    if (mediaFilters.length > 0) {
      mediaFilters.forEach(media => params.append('media', media));
    }
    if (typeFilters.length > 0) {
      typeFilters.forEach(type => params.append('type', type));
    }
    if (pointFilters.length > 0) {
      pointFilters.forEach(point => params.append('point', point));
    }    params.append('target', targetFilter);
    params.append('target', targetFilter);

    try {
      const response = await fetch(`${API_ENDPOINT_SHIPPING}?${params.toString()}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      setResults(data.result);
      if (data.is_valid) {
        if (data.result.length === 0) {
          setCountMessage('지금은 적합한 배송체험이 없네요');
          setNoResultsMessage('');
        } else {
          setCountMessage(`${categoryName} 카테고리 ${data.num_result}개의 체험이 있어요!<br /><small>플랫폼/업체/제공/신청/리뷰를 클릭해보세요.<br/>정렬해서 볼 수 있어요.</small>`);
        }
      } else {
        setCountMessage(`결과가 너무 많아서 사이트에 띄울 수 없어요! (${data.num_result}건)`);
        setNoResultsMessage('');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      setCountMessage('지금은 사용자가 많아요. 잠시 후 다시 시도해주세요!<br /><small>오랜 시간 문제가 지속될 경우 "공지" 댓글로 알려주세요.</small>');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (filterType, value) => {
    let setFilter;
    let filters;
  
    switch (filterType) {
      case 'media':
        setFilter = setMediaFilters;
        filters = mediaFilters;
        break;
      case 'type':
        setFilter = setTypeFilters;
        filters = typeFilters;
        break;
      case 'point':
        setFilter = setPointFilters;
        filters = pointFilters;
        break;
      default:
        return; // Unknown filter type, do nothing
    }

    setFilter(
      filters.includes(value)
        ? filters.filter(item => item !== value)
        : [...filters, value]
    );
  };

  const handleTargetRadioChange = (value) => {
    setTargetFilter(value);
  };

  const handleCategorySelectChange = (value, name) => {
    setCategoryFilter(value);
    setCategoryName(name);
  };

  useEffect(() => {
    search();
  }, [categoryFilter, mediaFilters, targetFilter, pointFilters]);

  const columns = useMemo(
    () => [
      { Header: '플랫폼', accessor: 'domain' },
      {
        Header: '업체',
        accessor: 'title',
        Cell: ({ row }) => <a href={row.original.url} target="_blank" rel="noopener noreferrer">{row.original.title}</a>,
      },
      { Header: '제공', accessor: 'offer' },
      {
        Header: '신청',
        accessor: 'apl_due_dt',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: '리뷰',
        accessor: 'pub_due_dt',
        Cell: ({ value }) => formatDate(value),
      },
    ],
    []
  );

  return (
    <div className={showNotice ? "container-notice" : "container"}>
      {showNotice && (
        <div className="notice">
          <div className="notice-content" dangerouslySetInnerHTML={{ __html: NOTICE_MESSAGE }}>
          </div>
        </div>
      )}
      
      <section className="header">
        <div className="title" id="title" aria-label="인플렉서(INFLEXER)">
          <a href={BASE_WEB_URL} title="인플렉서 홈페이지로 이동">
            <img src={LOGO_IMG} alt="인플렉서(INFLEXER) 로고" className="logo" />
            <span className="sr-only">인플렉서(INFLEXER)</span>
          </a>
        </div>
        <div className="header_link_box">
          <a id="opinion" href={OPINION_URL} target="_blank" title="의견 제출 폼">
            <img src={OPINION_ICON} alt="의견" className="icon_opinion" />
          </a>
          <a id="maker" href={NOTICE_URL} target="_blank" title="공지사항">
            <img src={NOTICE_ICON} alt="공지" className="icon_notice" />
          </a>
        </div>
      </section>

      <section className="main">
        <div className="slogan" id="slogan">
          배송체험 신청도 쉽게, 빠르게, 편리하게!
        </div>

        <div className="filter_button">
          <Filters 
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            mediaFilters={mediaFilters}
            handleCheckboxChange={handleCheckboxChange}
            typeFilters={typeFilters}
            pointFilters={pointFilters}
          />
        </div>

        <div className="select_container">
          <select name="select_category" id="category" onChange={(event) => handleCategorySelectChange(event.target.value, event.target.options[event.target.selectedIndex].text)}>
            <option value="FD_" selected>음식</option>
            <option value="BT_">뷰티</option>
            <option value="CL_">의류/악세서리</option>
            <option value="HG_">가전/가구</option>
            <option value="LF_">생활용품</option>
            <option value="HB_">취미/잡화</option>
            <option value="IT_">IT기기</option>
            <option value="CA_">차량</option>
            <option value="PA_">육아</option>
            <option value="PT_">반려동물</option>
            <option value="ET_">기타</option>
          </select>
        </div>
        
        <br/>
        현재 베타 기간으로, 분류가 정확하지 않을 수 있어요
        <br/>
        <br/>

        <div className='count_message' dangerouslySetInnerHTML={{ __html: countMessage }}></div>

        <div className="ads_google_banner" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', width: '100%', padding: '10px' }}>
          {showBannerAd && (
            <GoogleAdSense 
              client="ca-pub-7971368984672974" 
              slot="8845447829" 
              style={{ display: 'block' }} 
              format="auto" 
              responsive="true" 
            />
          )}
        </div>

        <div className='filter_target'>
          <label><input type="radio" name="target" value="TOTAL" checked={targetFilter === 'TOTAL'} onChange={() => handleTargetRadioChange('TOTAL')} />전체</label>
          <label><input type="radio" name="target" value="TODAY" checked={targetFilter === 'TODAY'} onChange={() => handleTargetRadioChange('TODAY')} />오늘오픈</label>
        </div>

        <div className='result'>
          {loading ? (
            <div className='no_result_message' dangerouslySetInnerHTML={{ __html: NoResultsMessage }}></div>
          ) : (
            <ResultsTable columns={columns} data={results} />
          )}
        </div>

        <div className='goto_top_button'>
            <button className="go-to-top" onClick={scrollToTop}>
                상단으로
            </button>
        </div>

      </section>

      {showFooterAd && (
          <section className="footer">
          </section>
      )}
    </div>
  );
};

export default AppShipping;
