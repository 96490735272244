/*
Inflexer Face: frontend of Inflexer

__author__ = 'Taeyang Yang (tyami@naver.com)'
__copyright__ = 'Copyright (C) 2024-, Taeyang Yang. All rights reserved.'
*/
import { useEffect, useRef } from 'react';
import { useNavermaps, useMap } from 'react-naver-maps';
import { makeMarkerClustering } from './utils/marker-cluster.js';

import clusterMarker1 from './images/cluster-marker-1.png';
import clusterMarker2 from './images/cluster-marker-2.png';
import clusterMarker3 from './images/cluster-marker-3.png';
import clusterMarker4 from './images/cluster-marker-4.png';
import clusterMarker5 from './images/cluster-marker-5.png';
import markerImg from './images/pin-marker.png';

function MarkerCluster({ markers, removeInputFocus }) {
    const navermaps = useNavermaps();
    const map = useMap();
    const clusterRef = useRef(null);
    const infoWindowRef = useRef(null);
    const mapClickListenerRef = useRef(null);

    // window.naver 객체 설정
    if (typeof window !== 'undefined') {
        window.naver = window.naver || {};
        window.naver.maps = window.naver.maps || navermaps;
    }

    const MarkerClustering = makeMarkerClustering(window.naver);
    const createHtmlMarker = (imageSrc) => ({
        content: `<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url('${imageSrc}');background-size:contain;"></div>`,
        size: navermaps.Size(40, 40),
        anchor: navermaps.Point(20, 20),
    });

    const htmlMarker1 = createHtmlMarker(clusterMarker1);
    const htmlMarker2 = createHtmlMarker(clusterMarker2);
    const htmlMarker3 = createHtmlMarker(clusterMarker3);
    const htmlMarker4 = createHtmlMarker(clusterMarker4);
    const htmlMarker5 = createHtmlMarker(clusterMarker5);

    useEffect(() => {
        // Clean up existing cluster when markers change
        if (clusterRef.current) {
            clusterRef.current.destroy();
        }

        if (!infoWindowRef.current) {
            infoWindowRef.current = new navermaps.InfoWindow({
                content: '',
                disableAnchor: true,
            });
        }

        const fmt_markers = markers.map((spot) => {
            const latlng = new navermaps.LatLng(spot.latitude, spot.longitude);
            const marker = new navermaps.Marker({
                position: latlng,
                map: map,
                icon: {
                    url: markerImg,
                    size: new navermaps.Size(30, 30),
                    scaledSize: new navermaps.Size(30, 30),
                    origin: new navermaps.Point(0, 0),
                    anchor: new navermaps.Point(15, 32),
                },
            });

            // Add click event listener to marker
            navermaps.Event.addListener(marker, 'click', () => {
                // Remove focus from input field
                if (removeInputFocus) {
                    removeInputFocus();
                }

                const content = `
                    <a href="${spot.url}" target="_blank" style="text-decoration:none; color:black; display:block;">
                        <div style="padding:10px; max-width:200px;">
                            <strong>${spot.title}</strong><br/>
                            ${spot.offer}
                        </div>
                    </a>
                `;
                infoWindowRef.current.setContent(content);
                infoWindowRef.current.open(map, marker);
            });

            return marker;
        });

        const cluster = new MarkerClustering({
            minClusterSize: 2,
            maxZoom: 14,
            map: map,
            markers: fmt_markers,
            disableClickZoom: false,
            gridSize: 120,
            icons: [
                htmlMarker1,
                htmlMarker2,
                htmlMarker3,
                htmlMarker4,
                htmlMarker5,
            ],
            indexGenerator: [10, 100, 200, 500, 1000],
            stylingFunction: function (clusterMarker, count) {
                clusterMarker
                    .getElement()
                    .querySelector('div:first-child').innerText = count;
            },
        });

        clusterRef.current = cluster;

        // Add click event listener to the map to close the InfoWindow when clicking elsewhere
        if (!mapClickListenerRef.current) {
            mapClickListenerRef.current = navermaps.Event.addListener(map, 'click', () => {
                if (infoWindowRef.current) {
                    infoWindowRef.current.close();
                }
            });
        }

        // Clean up when component unmounts or markers change
        return () => {
            cluster.setMap(null);
            if (infoWindowRef.current) {
                infoWindowRef.current.close();
                infoWindowRef.current = null;
            }
            if (mapClickListenerRef.current) {
                navermaps.Event.removeListener(mapClickListenerRef.current);
                mapClickListenerRef.current = null;
            }
            clusterRef.current = null;
        };
    }, [markers, map, navermaps, removeInputFocus]);

    return null;
}

export default MarkerCluster;
